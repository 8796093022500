import { forwardRef } from "react";
import PropTypes from "prop-types";

import getVariantChildStyles from "sholdi-shared/helpers/getVariantChildStyles";

import AlertCircle from "sholdi-icons/AlertCircle";

import { cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import ErrorMessageComponent from "../ErrorMessage";

export const variants = {
  none: {
    wrapper: "",
    inputWrapper: "px-3.5 py-2.5 border border-gray-300",
    label: "text-left",
  },
  href: {
    inputWrapper: "p-0",
    input: "px-3.5",
    beforeInputAddon:
      "pl-3.5 pr-3 py-2.5 mr-0 border-r border-gray-300 pointer-events-none",
  },
  colorPicker: {
    wrapper: "w-full p-0",
    inputWrapper: "border-white text-white w-12 h-12 p-0",
    input: "placeholder:text-white h-12",
  },
  newsletter: {
    wrapper: "mr-4",
    inputWrapper:
      "rounded border !bg-transparent !border-white text-white px-3.5 py-2.5",
    input: "bg-transparent text-white placeholder:text-white",
  },
  messenger: {
    wrapper: "w-full border border-gray-50 rounded py-4 px-4.5 mb-0",
    inputWrapper: "p-0 border-0 focus-within:shadow-none rounded-none",
    input: "rounded-none p-0",
  },
  status: {
    wrapper: "mb-0",
  },
  rangeSlider: {
    wrapper: "mb-0",
    // input: {
    //   "&::-webkit-outer-spin-button": {
    //     WebkitAppearance: "none",
    //     margin: 0,
    //   },
    //   "&::-webkit-inner-spin-button": {
    //     WebkitAppearance: "none",
    //     margin: 0,
    //   },
    //   MozAppearance: "textfield",
    // },
    afterInputAddon: "mx-0 px-0",
  },
  filterBar: {
    wrapper: "w-full md:w-60 text-gray-500 mb-0 order-4",
    inputWrapper: "px-2.5 border border-gray-300",
    input: "text-gray-300 h-10",
  },
  collection: {
    wrapper: "border-0 px-4 py-2",
    inputWrapper: "border-0 focus-within:shadow-none",
  },
};

const errorIconBase = cva("flex", {
  variants: {
    variant: getVariantChildStyles(variants, "errorIcon"),
  },
});

const ErrorIconBase = ({ className, variant, ...props }) => (
  <div className={twMerge(errorIconBase({ variant }), className)} {...props} />
);

ErrorIconBase.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export const ErrorIcon = ({ variant = "default", className, isSelect }) => (
  <ErrorIconBase
    variant={variant}
    className={twMerge(
      "h-full flex items-center pointer-events-none text-error-main",
      isSelect ? "pl-4" : "pl-0",
      className,
    )}
  >
    <AlertCircle className="size-3.5" />
  </ErrorIconBase>
);

ErrorIcon.displayName = "ErrorIcon";

ErrorIcon.propTypes = {
  errorColor: PropTypes.string,
  variant: PropTypes.string,
  isSelect: PropTypes.bool,
  className: PropTypes.string,
};

const afterBase = cva("flex", {
  variants: {
    variant: getVariantChildStyles(variants, "afterInputAddon"),
  },
});

const AfterBase = ({ className, variant, ...props }) => (
  <div className={twMerge(afterBase({ variant }), className)} {...props} />
);

AfterBase.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export const After = forwardRef(
  ({ after, variant = "default", isSelect, className }, ref) => (
    <AfterBase
      ref={ref}
      variant={variant}
      className={twMerge(
        "ml-3.5 text-inherit",
        isSelect ? "pl-4" : "pl-0",
        className,
      )}
    >
      {after}
    </AfterBase>
  ),
);

After.displayName = "After";

After.propTypes = {
  after: PropTypes.node.isRequired,
  variant: PropTypes.string,
  isSelect: PropTypes.bool,
  hasError: PropTypes.bool,
  className: PropTypes.string,
};

const beforeBase = cva("flex items-center", {
  variants: {
    variant: getVariantChildStyles(variants, "beforeInputAddon"),
  },
});

const BeforeBase = ({ className, variant, ...props }) => (
  <div className={twMerge(beforeBase({ variant }), className)} {...props} />
);

BeforeBase.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export const Before = forwardRef(
  ({ before, variant = "default", className }, ref) => (
    <BeforeBase
      ref={ref}
      variant={variant}
      className={twMerge("mr-2.5 text-inherit", className)}
    >
      {before}
    </BeforeBase>
  ),
);

Before.displayName = "Before";

Before.propTypes = {
  before: PropTypes.node.isRequired,
  variant: PropTypes.string,
  className: PropTypes.string,
};

const labelBase = cva("flex", {
  variants: {
    variant: getVariantChildStyles(variants, "label"),
  },
});

export const LabelBase = ({ className, variant, ...props }) => (
  <div className={twMerge(labelBase({ variant }), className)} {...props} />
);

LabelBase.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

const wrapper = cva("flex", {
  variants: {
    variant: getVariantChildStyles(variants, "inputWrapper"),
  },
});

export const InputWrapper = ({ className, variant, ...props }) => (
  <div className={twMerge(wrapper({ variant }), className)} {...props} />
);

InputWrapper.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

const error = cva("", {
  variants: {
    variant: getVariantChildStyles(variants, "error"),
  },
});

export const ErrorMessage = ({ className, variant, ...props }) => (
  <ErrorMessageComponent
    className={twMerge(error({ variant }), "mt-1", className)}
    {...props}
  />
);

ErrorMessage.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

const wrapperBase = cva("", {
  variants: {
    variant: getVariantChildStyles(variants, "wrapper"),
  },
});

export const WrapperBase = ({ className, variant, ...props }) => (
  <div className={twMerge(wrapperBase({ variant }), className)} {...props} />
);

WrapperBase.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};
