import { Children, cloneElement, forwardRef, isValidElement } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Flex from "sholdi-primitives/atoms/Flex";
import Box from "sholdi-primitives/atoms/Box";

import ListItem from "./ListItem";

const List = forwardRef(
  ({ heading, children, variant, icon, className, ...props }, ref) => (
    <Box
      as="ul"
      ref={ref}
      variant={variant}
      className={twMerge("flex flex-col bg-white p-2", className)}
      {...props}
    >
      {heading && (
        <Flex as="li" className="flex items-center justify-center w-full px-2">
          {heading && heading}
          {icon && icon}
        </Flex>
      )}
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { variant });
        }
        return null;
      })}
    </Box>
  ),
);

List.Item = ListItem;

List.defaultProps = {
  variant: "transparent",
};

List.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  heading: PropTypes.node,
  variant: PropTypes.string,
  icon: PropTypes.node,
  className: PropTypes.string,
};

List.displayName = "List";

export default List;
