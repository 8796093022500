import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Times from "sholdi-icons/Times";

import IconButton from "../IconButton";

const Close = ({ className, strokeWidth, iconClassName, ...props }) => (
  <IconButton
    title="Close"
    aria-label="Close"
    variant="close"
    className={twMerge("bg-transparent border-0 text-black", className)}
    {...props}
  >
    <Times
      strokeWidth={strokeWidth}
      color="currentColor"
      className={iconClassName}
    />
  </IconButton>
);

Close.propTypes = {
  strokeWidth: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

Close.displayName = "Close";

export default Close;
