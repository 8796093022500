import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Link from "sholdi-primitives/atoms/Link";

const CARDS = [
  {
    lgSrc: "/cards/discover_50.gif",
    src: "/cards/discover.png",
    link: "https://www.discover.com/",
    alt: "discover",
  },
  {
    lgSrc: "/cards/diners_50.gif",
    src: "/cards/Diners.png",
    link: "https://diners.hr/",
    alt: "diners",
  },
  {
    lgSrc: "/cards/AmericanExpress50.jpg",
    src: "/cards/AmericanExpress.png",
    link: "#",
    alt: "americanexpress",
  },
  {
    lgSrc: "/cards/MasterCard50.gif",
    src: "/cards/MasterCard.png",
    link: "https://www.mastercard.ba/bs-ba.html",
    alt: "mastercard",
  },
  {
    lgSrc: "/cards/maestro50.gif",
    src: "/cards/maestro.png",
    link: "https://brand.mastercard.com/brandcenter/more-about-our-brands.html",
    alt: "maestro",
  },
  {
    lgSrc: "/cards/Visa50.gif",
    src: "/cards/Visa.png",
    link: "https://www.visa.co.uk/about-visa/visa-in-europe.html",
    alt: "visa",
  },
];

const SIZES = {
  default: "h-6",
  lg: "h-9",
};

const WIDTH = 55;

const Cards = ({ size = "default", showAll, className, ...props }) => {
  const height = size === "lg" ? 36 : 24;

  return (
    <div className={twMerge("flex gap-2 flex-wrap", className)} {...props}>
      {CARDS.map((card) => (
        <Link
          href={card.link}
          key={card.link}
          target="_blank"
          className={SIZES[size]}
        >
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            loading="lazy"
            src={size === "lg" ? card.lgSrc : card.src}
            alt={card.alt}
            style={{ height: "100%", margin: 0 }}
            height={height}
            width={WIDTH}
          />
        </Link>
      ))}
      {showAll && (
        <>
          <Link
            href="https://www.mastercard.ba/bs-ba.html"
            target="_blank"
            className={SIZES[size]}
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              loading="lazy"
              src="/cards/mastercard-identity-check.png"
              alt="mastercard-identity-check"
              style={{ height: "100%", margin: 0 }}
              height={height}
              width={WIDTH}
            />
          </Link>
          <Link
            href="https://www.visaeurope.com/making-payments/verified-by-visa/"
            target="_blank"
            className={SIZES[size]}
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              loading="lazy"
              src="/cards/visa-secure.png"
              alt="visa-secure"
              height={height}
              width={WIDTH}
              style={{ height: "100%", margin: 0 }}
            />
          </Link>
          <Link
            href="https://www.monri.com/"
            target="_blank"
            className={SIZES[size]}
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              loading="lazy"
              src="/cards/unicredit.jpeg"
              alt="unicredit"
              height={height}
              width={123}
              style={{ height: "100%", margin: 0 }}
            />
          </Link>
        </>
      )}
    </div>
  );
};

Cards.propTypes = {
  size: PropTypes.oneOf(["default", "lg"]),
  showAll: PropTypes.bool,
  className: PropTypes.string,
};

export default Cards;
