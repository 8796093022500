/* eslint-disable react/prop-types */
import { forwardRef } from "react";
import PropTypes from "prop-types";

import Input from "sholdi-primitives/atoms/Input";

import FieldSet from "./FieldSet";

const Field = forwardRef(
  (
    {
      as: Component,
      label,
      name,
      description,
      error,
      variant,
      errorColor = "error.main",
      ...props
    },
    ref,
  ) => (
    <FieldSet
      as={Component}
      label={label}
      name={name}
      description={description}
      variant={variant}
      error={error}
      errorColor={errorColor}
      {...props}
      ref={ref}
    />
  ),
);

Field.displayName = "Field";

Field.defaultProps = {
  as: Input,
  variant: "none",
};

Field.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.shape({}),
  ]),
  label: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  errorColor: PropTypes.string,
  variant: PropTypes.oneOf([
    "none",
    "default",
    "href",
    "newsletter",
    "status",
    "collection",
    "messenger",
    "filterBar",
    "rangeSlider",
    "colorPicker",
  ]),
  error: PropTypes.string,
};

export default Field;
