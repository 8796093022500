import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import useTranslation from "sholdi-hooks/useTranslation";
import useSettings from "sholdi-hooks/useSettings";

import Link from "sholdi-primitives/atoms/Link";
import Text from "sholdi-primitives/atoms/Text";
import Box from "sholdi-primitives/atoms/Box";
import Logo from "sholdi-primitives/atoms/Logo";
import List from "../../molecules/List";
import ListItem from "../../molecules/List/ListItem";
import NewsletterSignUp from "../../molecules/NewsletterSignUp";
import Container from "../../molecules/Container";
import Cards from "../../molecules/Cards";

const Footer = ({ children, className, ...props }) => {
  const { t } = useTranslation();

  const { footerMenus } = useSettings();
  const [menu] = footerMenus || [];

  return (
    <Box
      {...props}
      className={twMerge(
        "bg-primary-main px-4 md:px-24 pt-12 md:pt-16",
        className,
      )}
    >
      <Container className="flex flex-col gap-8 md:gap-16">
        <NewsletterSignUp isFooter />
        <div className="grid flex-1 grid-cols-2 xl:grid-cols-6">
          {menu &&
            menu.menuItems.map((item) => (
              <List key={item.id} className="bg-transparent">
                <ListItem className="text-white font-light mb-4 p-0 cursor-default">
                  {item.title}
                </ListItem>
                {item.menuItems?.map((listItem) => (
                  <ListItem key={listItem.id} className="mb-3 p-0">
                    <Link
                      href={listItem.link}
                      className="text-white text-sm md:text-sm font-medium"
                    >
                      {listItem.title}
                    </Link>
                  </ListItem>
                ))}
              </List>
            ))}
        </div>
        {children}
      </Container>
      <Container>
        <Cards size="lg" showAll className="justify-end py-2" />
      </Container>
      <div className="flex bg-primary-main py-12 text-white">
        <Container className="flex justify-between items-center flex-col md:flex-row">
          <Link href="/" aria-label="logo" className="text-white">
            <Logo className="w-32 h-16" />
          </Link>

          <Text className="text-xl md:text-sm font-light text-white">
            © {new Date().getFullYear()} Sholdi d.o.o -{" "}
            {t("allRightsReserved")}
          </Text>
        </Container>
      </div>
    </Box>
  );
};

Footer.propTypes = {
  children: PropTypes.node,
  menus: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

Footer.displayName = "Footer";

export default Footer;
