import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Text from "sholdi-primitives/atoms/Text";

const ErrorMessage = ({ error, className, ...props }) => (
  <Text
    className={twMerge(
      "font-heading text-sm font-normal leading-5 text-error-main",
      className,
    )}
    {...props}
  >
    {error}
  </Text>
);

ErrorMessage.displayName = "ErrorMessage";

ErrorMessage.propTypes = {
  errorColor: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
};

export default ErrorMessage;
